import React from 'react';
import './style.scss'
import { Pie, defaults } from 'react-chartjs-2'
import Lottie from 'react-lottie';
import capacete from '../../img/capacete.json'
import PropTypes from 'prop-types';


const loadDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: capacete,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}

DashboardChart.propTypes = {
  countries: PropTypes.array.isRequired,
  count: PropTypes.array.isRequired,
}.isRequired;


export default function DashboardChart (props)  {
  const { countries, count } = props.totalUsers
  defaults.animation = false



  const options = {
    plugins: {
      legend: {
        display: "true",
        position: "bottom"
      }
    }
  };
  const data = {
    labels: countries,
    datasets: [
      {
        label: '# of Votes',
        data: count,
        backgroundColor: [
          '#1988FF',
          '#7FBDFF',
          '#B2D7FF',
          '#294EFF',
        ],
        borderColor: [
          'rgb(255, 255, 255, 255)',
          'rgb(255, 255, 255, 255)',
          'rgb(255, 255, 255, 255)',
          'rgb(255, 255, 255, 255)'
        ],
        borderWidth: 1,

      },
    ],
  };

  return (

    <div className="dashboardChart">
      <div className="dashboardChart-title">Participações por Países</div>
      {count && countries ?
        <Pie data={data} options={options} className="dashboardChart-chart" />
        : <Lottie options={loadDefaultOptions} height={200} width={200} />}
    </div>
  );
}