import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import './style.scss'
import Logosipat from '../../img/SipatNexa-Logo-branca.png'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import btn from '../../img/arrow-button.svg'
import { savingLocalRefreshToken } from '../../Services/httpRequest'
import LoadLogin from '../../Components/LoadLogin'
import MyContext from '../../context/Context';


export default function Login (props) {
    const home = process.env.REACT_APP_SIPAT_API,
        history = useHistory()
        const {
            errorLogin, 
            seterrorLogin,
            userEmail,
            setUserEmail,
            userPassword,
            setUserPassword,
            loadhidden,
            setLoadhidden,
        } = useContext(MyContext),
        { setUser } = props,

        userLocalData = (localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null)

    useEffect(() => {
        userLocalData && history.push('./dashboard')
    }, [])

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
         setLoadhidden(true)
        const response = await fetch(`${home}/dashboard/signIn`, 
            {
                method: 'POST',headers:{'Content-Type': 'application/json; charset=utf-8'}, body: JSON.stringify( {
                    "email": userEmail,
                    "password": userPassword
                })
            }),
            data = await response.json()
      
        setUser(data)
        if (data.status === 'Success') {         
            savingLocalRefreshToken(data.data.tokens.ExpiresIn)
            localStorage.setItem("user", JSON.stringify(data))
            localStorage.setItem("idToken", JSON.stringify(data.data.tokens.IdToken))
            window.open('./dashboard','_self');

         

        } else {
            seterrorLogin(true)
            setLoadhidden(false)
        }
    }
    const handleChangeEmail = (event) => {
        setUserEmail(event.target.value)
    }
    const handleChangePassword = (event) => {
        setUserPassword(event.target.value)
    }

    const [passwordhidden, setpasswordhidden] = useState(true)
    return (
        <div className="login">

            <div className="login-left">
                <img className="login-bgleft" src={Logosipat} alt="" />
                <h1>Dashboard</h1>
            </div>
            <div className="login-right">
                <LoadLogin loadhidden={loadhidden} />
                <h1 className="login-title">Faça o login para acessar<br /> o Dashboard do SIPAT Nexa</h1>
                <TextField className="login-nameinput" label='Email' type="email" onKeyDown={keyPress}
                    autoComplete="new-password"
                    onChange={handleChangeEmail}
                    id="nome"
                />
                <TextField
                    className="login-passwordinput"
                    id="input-with-icon-textfield"
                    label='Senha' type={passwordhidden ? "password" : "text"}
                    autoComplete="new-password"
                    onKeyDown={keyPress}
                    onChange={handleChangePassword}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" >
                                <IconButton aria-label="toggle password visibility" onClick={() => { setpasswordhidden(!passwordhidden) }}>
                                    {passwordhidden ? <VisibilityIcon style={{ color: "#3C3C3C" }} /> : <VisibilityOffIcon style={{ color: "#3C3C3C" }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        minLength:8,
                        maxLength:16,
                    }} />

                <label htmlFor="login-submit" className="login-error" style={{ display: errorLogin ? 'initial' : 'none' }}>Senha ou email incorreto, digite novamente</label>
                <button className="login-submit" onClick={handleSubmit}>FAZER LOGIN <img src={btn} alt="" /></button>
            </div>
        </div >
    );
}