import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MyContext from './Context';

function Provider({ children }) {
   const [usersgeral, setUsersGeral] = useState('')
   const [usersUnit, setUsersUnit] = useState('')
   const [lecturesgeral, setLecturesGeral] = useState('')
   const [lecturesUnit, setLecturesUnit] = useState('')
   const [phrasesGeral, setPhrasesGeral] = useState('')
    const[phrasesUnit, setPhrasesUnit] = useState('')
   const [quizGeral, setQuizGeral] = useState('')
   const [huntWords, setHuntWords] = useState('')
   const [quizUnit, setQuizUnit] = useState('')
   const [memoryGameGeral, setMemoryGameGeral] = useState('')
   const [memoryGameUnit, setMemoryGameUnit] = useState('')
    const [totalUsers, setTotalUsers] = useState('')
   const [rankGeral, setRankGeral] = useState('')
   const [rankEmployee, setRankEmployee] = useState('')
   const [rankFamily, setRankFamily] = useState('')
   const [rankUnit, setRankUnit] = useState('')
   const [tables, setTables] = useState(true)
   const [unity, setUnity] = useState('1')
   const [isLoading, setIsLoading] = useState(false)
   const [errorLogin, seterrorLogin] = useState(false)
   const [userEmail, setUserEmail] = useState('')
   const [userPassword, setUserPassword] = useState('')
   const [loadhidden, setLoadhidden] = useState(false)
   const [isRankingLoading, setIsRankingLoading] = useState('')

    const state = {
    usersgeral,
    setUsersGeral,
    usersUnit,
    setUsersUnit,
    lecturesgeral,
    setLecturesGeral,
    lecturesUnit,
    setLecturesUnit,
    phrasesGeral,
    setPhrasesGeral,
    phrasesUnit,
    setPhrasesUnit,
    quizGeral,
    setQuizGeral,
    huntWords,
    setHuntWords,
    quizUnit,
    setQuizUnit,
    memoryGameGeral,
    setMemoryGameGeral,
    memoryGameUnit,
    setMemoryGameUnit,
    totalUsers,
    setTotalUsers,
    rankGeral,
    setRankGeral,
    rankEmployee,
    setRankEmployee,
    rankFamily,
    setRankFamily,
    rankUnit,
    setRankUnit,
    tables,
    setTables,
    unity,
    setUnity,
    isLoading,
    setIsLoading,
    errorLogin,
    seterrorLogin,
    userEmail,
    setUserEmail,
    userPassword,
    setUserPassword,
    loadhidden,
    setLoadhidden,
    isRankingLoading,
    setIsRankingLoading,
  };

  return (
    <MyContext.Provider value={ state }>
      {children}
    </MyContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;