
import React, { useContext } from 'react';

import './style.scss'
import Lottie from "react-lottie";
import capacete from "../../img/capacete.json";
import PropTypes from 'prop-types';
// import myContext from '../../context/Context';


DashboardCardSubscribers.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
}.isRequired;


export default function DashboardCardSubscribers (props) {
    const { text, value, isLoading } = props,

        loadDefaultOptions = {
            loop: true,
            autoplay: true,
            animationData: capacete,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        returnValue = () => {
            if (isLoading) return  <Lottie options={loadDefaultOptions} height={90} width={90} style={{ margin: '-25px' }} />
            else{
                if(value || value === 0 ) return value
                else return  <Lottie options={loadDefaultOptions} height={90} width={90} style={{ margin: '-25px' }} />
            }           
        }
    return (
        <div className="DashboardCardSubscribers">
            <h4 className="DashboardCardSubscribers-text">{text}</h4>
            <h3 className="DashboardCardSubscribers-value">{returnValue()}</h3>
        </div>
    );
}