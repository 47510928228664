const home = process.env.REACT_APP_SIPAT_API

export const savingLocalRefreshToken = (tokenExpiresIn) => {
    const expireInDate = ((Date.now() / 1000) + tokenExpiresIn - 1800)   
     localStorage.setItem("expireInDate", JSON.stringify(expireInDate))
}


export const get = async (url, headers, refreshToken, history, setIdToken, method = 'GET') => {
    await getToken(refreshToken, history, setIdToken, headers)    
    const data = await fetch(url, {
        method: method,
        headers: headers
    })
    const response = await data.json()
    return response
}


export const getToken = async (refreshToken, history, setToken, headers) => {
  
    const expiresInRefreshToken = parseInt(localStorage.getItem("expireInDate"))
     
    if ( expiresInRefreshToken < parseInt(Date.now() / 1000)) { 
       
        const data = await fetch(`${home}/users/refresh`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "refreshToken": refreshToken
            })
        })
        const response = await data.json()      
        if (response.status === 'Success') {
            localStorage.setItem("idToken", JSON.stringify(response.data.IdToken))
           await setToken(response.data.IdToken)
            savingLocalRefreshToken(response.data.ExpiresIn)

        } else {
            localStorage.setItem("user", null)
            history.push('./')
        }
    }
}