
import React from "react";
import './style.scss'
import DashboardRankLine from '../DashboardRankLine';
import Lottie from 'react-lottie';
import capacete from '../../img/capacete.json'
import PropTypes from 'prop-types';

DashboardRankUnit.propTypes = {
    rank: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
}.isRequired;


export default function DashboardRankUnit (props) {
    const { rank, isLoading } = props
    const loadDefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: capacete,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const getData = () => {
        if (isLoading) return <Lottie options={loadDefaultOptions} height={200} width={200} />
        else {
            if (rank) {
                if (rank.length<1){return <h1 className='dashboardRankUnit-empty'>Não há registros</h1>}
                else{
                    return (rank.map((option, index) => (
                        <DashboardRankLine key={index} name={option.name!==null?option.name:option.email} position={index + 1} coins={option.coins} group={option.group} />
                    )))
                }              
               
            } else {
                return <Lottie options={loadDefaultOptions} height={200} width={200} />
            }
        }
    }


    return (
        <div className="dashboardRankUnit">
            <div className="dashboardRankUnit-titleArea">
                <h2>{props.title}</h2>

            </div>
            <div className="dashboardRankUnit-headerArea">
                <div className="dashboardRankUnit-headerPosition">
                    <h6 className="dashboardRankUnit-headerSect">#</h6>
                </div>
                <div className="dashboardRankUnit-headerName">
                    <h6 className="dashboardRankUnit-headerSect">Nome</h6>
                </div>
                <div className="dashboardRankUnit-headerUnity">
                    <h6 className="dashboardRankUnit-headerSect">{props.unityTitle}</h6>
                </div>
                <div className="dashboardRankUnit-headerCoins">
                    <h6 className="dashboardRankUnit-headerSect">Zincoins</h6>
                </div>
            </div>
            <div className="dashboardRankUnit-contentArea">
                {getData()}
            </div>
        </div>
    );
}