import React from 'react';
import './style.scss'
import PropTypes from 'prop-types';


DashboardRankLine.propTypes = {
    position: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    coins: PropTypes.number.isRequired,
}.isRequired;

export default function DashboardRankLine (props) {

    const {position,name,group,coins} = props
    return (
        <div className="dashboardRankLine">
             <div className="dashboardRankLine-Position">
                    <h6 className="dashboardRankLine-Sect">{position}</h6>
                </div>
                <div className="dashboardRankLine-Name">
                    <h6 className="dashboardRankLine-Sect">{name}</h6>
                </div>
                <div className="dashboardRankLine-Unity">
                    <h6 className="dashboardRankLine-Sect">{group}</h6>
                </div>
                <div className="dashboardRankLine-Coins">
                    <h6 className="dashboardRankLine-Sect">{coins}</h6>
                </div>

            <div >
            </div>
        </div>
    );
}