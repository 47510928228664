import React from 'react';
import './style.scss'
import Lottie from "react-lottie";
import capacete from "../../img/capacete.json";
import PropTypes from 'prop-types';


DashboardSmCard.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
}.isRequired;


export default function DashboardSmCard (props) {
    const { text, value,isLoading } = props,      
        loadDefaultOptions = {
            loop: true,
            autoplay: true,
            animationData: capacete,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        },
        returnValue=()=>{
            if(isLoading) return <Lottie options={loadDefaultOptions} height={90} width={90} style={{margin:'-25px'}} />
            else return value
          //return  !isLoading || value || value ===0?value: 
        }

    return (
        <div className="dashboardSmCard">
            <h4 className="dashboardSmCard-text">{text}</h4>
            <h3 className="dashboardSmCard-value">{returnValue()}</h3>
        </div>
    );
}