import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';


DashboardFilter.propTypes = {
    setUnity: PropTypes.func,
    setIsLoading: PropTypes.func,
    setTables: PropTypes.func,
}.isRequired;

export default function DashboardFilter (props) {
    const home = process.env.REACT_APP_SIPAT_API
    const [geralactive, setGeralactive] = useState(true)
    const [unityactive, setUnityactive] = useState(false)
    const [unities, setUnities] = useState([])
    const { setUnity, setIsLoading } = props

    const getUnity = async () => {

        const response = await fetch(`${home}/groups?type=unit`),
            data = await response.json()
        setUnities(data.data)

    }
    // <></>
    useEffect(() => {
        getUnity()
    }, []);

    const handleChangeUnity = (event) => {
        setUnity(event.target.value);
        console.warn(event.target.value);
        setIsLoading(true)
    }

    const handleClickGeral = () => {
        setUnityactive(false)
        setGeralactive(true)
        props.setTables(true)
    }

    const handleClickUnity = () => {
        setUnityactive(true)
        setGeralactive(false)
        props.setTables(false)
    }

    return (
        <div className="dashboardFilter">
            <div className="dashboardFilter">
                <h1 className="dashboardFilter-title">Filtrar por</h1>
                <div className="dashboardFilter-btns">
                    <div className={`dashboardFilter-geral${geralactive ? '_active' : ''}`} onClick={handleClickGeral}>Geral</div>
                    <div className={`dashboardFilter-unity${unityactive ? '_active' : ''}`} onClick={handleClickUnity}>Unidade</div>
                    <select className="dashboard-selectunity" style={{ display: unityactive ? 'initial' : 'none' }} onChange={handleChangeUnity} name="unities" id="unities">
                        {unities.map((option) => (
                            <option key={option.id} value={option.id} >
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div></div>
            <h1 className="dashboardFilter-title">Atividades no SIPAT Nexa</h1>
            <br/>
        </div>
    );
}