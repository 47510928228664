import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import dashlogo from '../../img/dash-logo-nexa.png';
import './style.scss'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DashboardDownloadBtn from '../DashboardDownloadBtn';
import ReactExport from "react-export-excel";
import PropTypes from 'prop-types';

DashboardNav.propTypes = {
    userName: PropTypes.string.isRequired,
    tables: PropTypes.bool.isRequired,
    subscribersUsersgeral: PropTypes.number.isRequired,
    subscribersUsersUnit: PropTypes.number.isRequired,
    lecturesGeral: PropTypes.number.isRequired,
    lecturesUnit: PropTypes.number.isRequired,
    phrasesGeral: PropTypes.number.isRequired,
    phrasesUnit: PropTypes.number.isRequired,
    quizGeral: PropTypes.number.isRequired,
    quizUnit: PropTypes.number.isRequired,
    memoryGameGeral: PropTypes.number.isRequired,
    memoryGameUnit: PropTypes.number.isRequired,
    totalUsers: PropTypes.object.isRequired,
    rankGeral: PropTypes.array.isRequired,
    rankEmployee: PropTypes.array.isRequired,
    rankFamily: PropTypes.array.isRequired,
    rankUnit: PropTypes.array.isRequired,
    unity: PropTypes.string.isRequired,
   }.isRequired;

   const {ExcelFile} = ReactExport;
   const {ExcelSheet, ExcelColumn } = ExcelFile;


   

export default function DashboardNav (props) {
    const { userName, tables, subscribersUsersgeral, subscribersUsersUnit, lecturesGeral, lecturesUnit, phrasesGeral, phrasesUnit, quizGeral, quizUnit, memoryGameGeral, memoryGameUnit, totalUsers, rankGeral, rankEmployee, rankFamily, rankUnit, unity } = props
    const [unities, setUnities] = useState()
    const [currentUnity, setCurrentUnity] = useState('')
    const home = process.env.REACT_APP_SIPAT_API


    const returnIndex = () => {
        return unity == 11 ? 9 : parseInt(unity) - 1
    }


    useEffect(() => {

        const getUnity = async () => {

            const response = await fetch(`${home}/groups?type=unit`),
                data = await response.json()
            setUnities(data.data)

            setCurrentUnity(data.data[returnIndex()].name)

        }
        getUnity()
    }, []);

    useEffect(() => {
        if (unities !== undefined) {
            unity == 11 ? setCurrentUnity(unities[9].name) : setCurrentUnity(unities[parseInt(unity) - 1].name)
        }

    }, [unity]);


   
    const dataSetActivitiesCount = [
        {
            activity: 'Palestras concluidas',
            count: lecturesGeral
        },
        {
            activity: 'SIPAT frases',
            count: phrasesGeral
        },
        {
            activity: 'Quiz',
            count: quizGeral
        },
        {
            activity: 'Jogo da memória',
            count: memoryGameGeral
        },
    ]
    const dataSetActivitiesCountUnity = [
        {
            activity: 'Palestras concluidas',
            count: lecturesUnit
        },
        {
            activity: 'SIPAT frases',
            count: phrasesUnit
        },
        {
            activity: 'Quiz',
            count: quizUnit
        },
        {
            activity: 'Jogo da memória',
            count: memoryGameUnit
        },
    ]

    const dataSetCountGeral = [
        {
            unity: 'SIPAT NEXA',
            count: subscribersUsersgeral
        }]
    const dataSetCountUnity = [
        {
            unity: currentUnity,
            count: subscribersUsersUnit
        }
    ]
    const dataSetCountriesCount = [
        {
            country: totalUsers ? totalUsers.countries[0] : 0,
            count: totalUsers ? totalUsers.count[0] : 0
        },
        {
            country: totalUsers ? totalUsers.countries[1] : 0,
            count: totalUsers ? totalUsers.count[1] : 0,
        },
        {
            country: totalUsers ? totalUsers.countries[2] : 0,
            count: totalUsers ? totalUsers.count[2] : 0,
        },
        {
            country: totalUsers ? totalUsers.countries[3] : 0,
            count: totalUsers ? totalUsers.count[3] : 0,
        }

    ]
    const sheetDownload = () => {
        if (tables) {
            return <ExcelFile element={<DashboardDownloadBtn />} filename="Dados Gerais do Evento SIPAT NEXA" >
                <ExcelSheet data={dataSetCountGeral} name="Quantidade de inscritos">
                    <ExcelColumn label={"Unidade"} value={"unity"} />
                    <ExcelColumn label={"Inscritos"} value={"count"} />
                </ExcelSheet>

                <ExcelSheet data={rankGeral} name="Rank Geral Sipat Nexa">
                    <ExcelColumn label={"Nome"} value={"name"} />
                    <ExcelColumn label={"Email"} value={"email"} />
                    <ExcelColumn label={"Grupo"} value={"group"} />
                    <ExcelColumn label={"Coins"} value={"coins"} />
                </ExcelSheet>
                <ExcelSheet data={rankEmployee} name="Rank de Familiares Sipat Nexa">
                    <ExcelColumn label={"Nome"} value={"name"} />
                    <ExcelColumn label={"Email"} value={"email"} />
                    <ExcelColumn label={"Grupo"} value={"group"} />
                    <ExcelColumn label={"Coins"} value={"coins"} />
                </ExcelSheet>
                <ExcelSheet data={rankFamily} name="Rank de Empregados Sipat Nexa">
                    <ExcelColumn label={"Nome"} value={"name"} />
                    <ExcelColumn label={"Email"} value={"email"} />
                    <ExcelColumn label={"Grupo"} value={"group"} />
                    <ExcelColumn label={"Coins"} value={"coins"} />
                </ExcelSheet>
                <ExcelSheet data={dataSetCountriesCount} name="Total de usuarios Sipat Nexa">
                    <ExcelColumn label={"Pais"} value={"country"} />
                    <ExcelColumn label={"Inscritos"} value={"count"} />
                </ExcelSheet>
                <ExcelSheet data={dataSetActivitiesCount} name="Atividades concluidas Sipat Nexa">
                    <ExcelColumn label={"Atividade"} value={"activity"} />
                    <ExcelColumn label={"Inscritos"} value={"count"} />
                </ExcelSheet>
            </ExcelFile>
        } else {
            return <ExcelFile element={<DashboardDownloadBtn />} filename={`Dados relativos a unidade_${currentUnity.replace('/','_')}`} >
                <ExcelSheet data={dataSetCountUnity} name="Quantidade de inscritos na">
                    <ExcelColumn label={"Unidade"} value={"unity"} />
                    <ExcelColumn label={"Inscritos"} value={"count"} />
                </ExcelSheet>
                <ExcelSheet data={rankUnit} name={`Rank Unidade ${currentUnity} `}>
                    <ExcelColumn label={"Nome"} value={"name"} />
                    <ExcelColumn label={"Email"} value={"email"} />
                    <ExcelColumn label={"Grupo"} value={"group"} />
                    <ExcelColumn label={"Coins"} value={"coins"} />
                </ExcelSheet>
                <ExcelSheet data={dataSetActivitiesCountUnity} name="Atividades concluidas Sipat Nexa">
                    <ExcelColumn label={"Atividade"} value={"activity"} />
                    <ExcelColumn label={"Inscritos"} value={"count"} />
                </ExcelSheet>
            </ExcelFile>
        }
    }

    const history = useHistory()
    const handleLogout = () => {

        // localStorage.setItem("user", null)
        localStorage.clear();
        history.push('/')
    }

    return (

        <div className="dashboardNav">
            <div className="dashboardNav-contentLeft">
                <div className="dashboardNav-logoArea">
                    <img src={dashlogo} className="dashboardNav-logo" alt="Logo Nexa" />
                    <h1 className="dashboardNav-title">Dashboard</h1>
                </div>
                {sheetDownload()}

            </div>
            <div className="dashboardNav-contentRight">
                <AccountCircleIcon className="dashboardNav-usericon" />
                <h2 className="dashboardNav-user">{userName}</h2>
                <MeetingRoomIcon fontSize='large' onClick={handleLogout} className="dashboardNav-logouticon" />
            </div>

        </div>
    );
}
