
import React from "react";
import "./style.scss"
import Lottie from "react-lottie";
import capacete from "../../img/capacete.json";
import PropTypes from "prop-types";

LoadLogin.propTypes = {
    loadhidden: PropTypes.bool.isRequired,
}

export default function LoadLogin (props) {
    const { loadhidden } = props,
       loadDefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: capacete,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
      
    }
    return (
        <div className='loadLogin' style={{display:loadhidden?'flex':'none'}}>
             <Lottie options={loadDefaultOptions} height={'40vh'} width={'40vh'} />
        </div>
    );
}