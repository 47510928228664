
import React, { useEffect, useState, useRef, useContext } from 'react';
import './style.scss'
import DashboardRankLine from '../DashboardRankLine';
import Lottie from 'react-lottie';
import capacete from '../../img/capacete.json';
import { getToken, get } from '../../Services/httpRequest';
import PropTypes from 'prop-types';
import { getAllByAltText } from '@testing-library/react';
import myContext from '../../context/Context';


DashboardRankGeral.propTypes = {
    rank: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    }.isRequired;


export default function DashboardRankGeral (props)  {
      const loadDefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: capacete,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const {
        isRankingLoading,
        setIsRankingLoading,
        rankingRequest,
        setRankingRequest,
    } = useContext(myContext);
    // home = process.env.REACT_APP_SIPAT_API;
    const [currentPage, setCurrentPage] = useState(1);
    const [rank, setRank] = useState([]);
    const [title, setTitle] = useState('');
    const [request, setRequest] = useState('');
    const [geralValidation, setGeralValidation] = useState(false);
    const [familyValidation, setFamilyValidation] = useState(false);
    const [employeeValidation, setEmployeeValidation] = useState(false);


      useEffect(() => {

        const intersectionObserver = (new IntersectionObserver((entries) => {
            if(entries.some((entry) => entry.isIntersecting)) {
                 if(rank) {
                setCurrentPage((currentPageInsideState) => currentPageInsideState + 1);
                 }
            }
        }));
         intersectionObserver.observe(sentinel.current)
         return () =>  intersectionObserver.disconnect();
         }, []);

         useEffect(() => {
                props.rankPagination(props.title, currentPage)
            }, [currentPage]);

        //    const testRequest = async () => {
        //         const request = await get(`${home}/dashboard/ranking?company=2&page=${page}&limit=10`, myHeaders, refreshToken, history, setToken)
        //         setRequest(request.data.items);
        //     }

            useEffect(() => {
                setRank(props?.rank?.items || props?.rank);
                setTitle(props?.rank?.title || props?.title);
                }, [props.rank]);

                const sentinel = useRef(null);
                
                // const loginValidation = async () =>{
                //     const validation = rankingRequest ? currentPage >= rankingRequest.meta.totalPages : false; 
                // }       
    //    const getData = () => {
    //     if (props.rank.length) {
    //     return (props?.rank?.map((option, index) => (
    //          <DashboardRankLine key={index} 
    //         name={option.name!==null?option.name:option.email} 
    //         position={index + 1} 
    //         coins={option.coins} 
    //         group={option.unit==null? option.group:option.unit}
    //         />   

    //      )))
    //      } else if ( props.title === "Ranking Geral") {
    //         return (props?.rank?.items?.map((option, index) => (
    //             <DashboardRankLine key={index} 
    //             name={option.name!==null?option.name:option.email} 
    //             position={index + 1} 
    //             coins={option.coins} 
    //             group={option.unit==null? option.group:option.unit}
    //             />    
                
    //         )))
    //      }
    //      return  <Lottie options={loadDefaultOptions} height={200} width={200} />
    //     }
    const { isLoading } = props;
    return (
        
        <div className="dashboardRankGeral">
            <div className="dashboardRankGeral-titleArea">
                <h2>{props.title}</h2>
            </div>
            <div className="dashboardRankGeral-headerArea">
                <div className="dashboardRankGeral-headerPosition">
                    <h6 className="dashboardRankGeral-headerSect">#</h6>
                </div>
                <div className="dashboardRankGeral-headerName">
                    <h6 className="dashboardRankGeral-headerSect">Nome</h6>
                </div>
                <div className="dashboardRankGeral-headerUnity">
                    <h6 className="dashboardRankGeral-headerSect">{props.unityTitle}</h6>
                </div>
                <div className="dashboardRankGeral-headerCoins">
                    <h6 className="dashboardRankGeral-headerSect">Zincoins</h6>
                </div>
            </div>
            <div className="dashboardRankGeral-contentArea">
            {rank?.length > 0 ? rank.map((option, index) => (
                <DashboardRankLine key={index}
                name={option.name!==null? option.name:option.email}
                position={index + 1}    
                coins={option.coins}
                group={option.unit==null? option.group:option.unit}
                />
            )): null}
            <span ref={sentinel} style={{  display: rank?.length && isLoading ? "block" : "none" }}><Lottie options={loadDefaultOptions} height={200} width={200} /></span> 
            </div>
        </div>
    );
}