import React from 'react';
import './style.scss'
import DescriptionIcon from '@material-ui/icons/Description'


export default function DashboardDownloadBtn () {   
    return (       
        <div className="dashboardDownloadBtn">
             <DescriptionIcon/>
             <h4 className="dashboardDownloadBtn-exporttext">Exportar dados</h4>
         </div>
    );
}