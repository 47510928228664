
import React, { useState, useEffect, useContext } from 'react';
import './style.scss'
import DashboardNav from '../../Components/DashboardNav';
import DashboardSmCard from '../../Components/DashboardSmCard';
import DashboardFilter from '../../Components/DashboardFilter';
import DashboardCardSubscribers from '../../Components/DashboardCardSubscribers';
import DashboardRankGeral from '../../Components/DashboardRankGeral';
import DashboardRankUnit from '../../Components/DashboardRankUnit';
import DashboardChart from '../../Components/DashboardChart';
import { useHistory } from 'react-router-dom';
import { getToken, get } from '../../Services/httpRequest';
import MyContext  from '../../context/Context';

export default function Dashboard () {

    const userLocalData = JSON.parse(localStorage.getItem("user"))
        // eslint-disable-next-line no-undef
        const {
            usersgeral,
            setUsersGeral,
            usersUnit,
            setUsersUnit,
            lecturesgeral,
            setLecturesGeral,
            lecturesUnit,
            setLecturesUnit,
            phrasesGeral,
            setPhrasesGeral,
            phrasesUnit,
            setPhrasesUnit,
            quizGeral,
            setQuizGeral,
            huntWords,
            setHuntWords,
            quizUnit,
            setQuizUnit,
            memoryGameGeral,
            setMemoryGameGeral,
            memoryGameUnit,
            setMemoryGameUnit,
            totalUsers,
            setTotalUsers,
            rankGeral,
            setRankGeral,
            rankEmployee,
            setRankEmployee,
            rankFamily,
            setRankFamily,
            rankUnit,
            setRankUnit,
            tables,
            setTables,
            unity,
            setUnity,
            isLoading,
            setIsLoading,
        } = useContext(MyContext);
        const [ isGeralLoading, setIsGeralLoading] = useState(false)
        const [ isEmployeeLoading, setIsEmployeeLoading] = useState(false)
        const [ isFamilyLoading, setIsFamilyLoading] = useState(false),
        home = process.env.REACT_APP_SIPAT_API,    
        userName = userLocalData.data.claims.name,
        refreshToken = userLocalData.data.tokens.RefreshToken,
        [token, setToken] = useState(JSON.parse(localStorage.getItem("idToken"))),
        myHeaders = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        const validation = unity === '11'?'10':unity;


        const getData = async () => {
            
            const responseUsersGeral = get(`${home}/dashboard/users/count`, myHeaders, refreshToken, history, setToken)
            responseUsersGeral.then(responseUsersGeral => { setUsersGeral(responseUsersGeral.data.count) })
    
            const responseActivitiesGeral = get(`${home}/dashboard/activities/count`, myHeaders, refreshToken, history, setToken)
            responseActivitiesGeral.then(responseActivitiesGeral => {
                setLecturesGeral(responseActivitiesGeral.data.lecture)
                setPhrasesGeral(responseActivitiesGeral.data.phrases)
                setQuizGeral(responseActivitiesGeral.data.quiz)
                setMemoryGameGeral(responseActivitiesGeral.data.memoryGame)
            })
            const totalUsers = get(`${home}/dashboard/users/count?country`, myHeaders, refreshToken, history, setToken)
            totalUsers.then(totalUsers => { setTotalUsers(totalUsers.data) })
    
            // const rankGeral = get(`${home}/dashboard/ranking?`, myHeaders, refreshToken, history, setToken)
            // rankGeral.then(rankGeral => { setRankGeral(rankGeral.data.items) })
    
            // const rankEmployee = get(`${home}/dashboard/ranking?company=1`, myHeaders, refreshToken, history, setToken)
            // rankEmployee.then(rankEmployee => { setRankEmployee(rankEmployee.data.items) })
    
            // const rankFamily = get(`${home}/dashboard/ranking?company=2`, myHeaders, refreshToken, history, setToken)
            // rankFamily.then(rankFamily => { setRankFamily(rankFamily.data.items) })
    
            const rankPhrases = get(`${home}/dashboard/activities/count/`, myHeaders, refreshToken, history, setToken)
            rankPhrases.then(data => { setHuntWords(data.data.phrases) })

        }

        const rankPagination = async (rankType, page) => {
            console.log('entrei na função')
            console.log(rankType)
            if(rankType === 'Ranking Geral')  {
                const request = await get(`${home}/dashboard/ranking?page=${page}&limit=10`, myHeaders, refreshToken, history, setToken);
                setRankGeral((prevState) =>  [...prevState, ...request.data.items]);
               request.data.meta.currentPage <= request.data.meta.totalPages ? setIsGeralLoading(true) : setIsGeralLoading(false)
            
            }
            if(rankType === 'Ranking Empregados (P e T)')  {
                const request = await get(`${home}/dashboard/ranking?company=1&page=${page}&limit=10`, myHeaders, refreshToken, history, setToken)
                setRankEmployee((prevState) =>  [...prevState, ...request.data.items]);
                request.data.meta.currentPage <= request.data.meta.totalPages ? setIsEmployeeLoading(true) : setIsEmployeeLoading(false)
            }
            if(rankType === 'Ranking Família')  {
                const request = await get(`${home}/dashboard/ranking?company=2&page=${page}&limit=10`, myHeaders, refreshToken, history, setToken)
                setRankFamily((prevState) =>  [...prevState, ...request.data.items]);
                request.data.meta.currentPage <= request.data.meta.totalPages ? setIsFamilyLoading(true) : setIsFamilyLoading(false)
            }
        }
    

    useEffect(() => {
        userLocalData.data === null && history.push('./')
        getToken(refreshToken, history, setToken, myHeaders).then(getData())
     
    }, []);


    const getNewData = async () => {          

        const unityData = get(`${home}/dashboard/ranking?unit=${validation}`, myHeaders, refreshToken, history, setToken)
        unityData.then(unityData => { setRankUnit(unityData.data) })

        const dataActivitiesUnit = get(`${home}/dashboard/activities/count?unit=${validation}`, myHeaders, refreshToken, history, setToken)
        dataActivitiesUnit.then(dataActivitiesUnit => {
            setLecturesUnit(dataActivitiesUnit?.data?.lecture || 0)
            setPhrasesUnit(dataActivitiesUnit?.data?.phrases || 0)
            setQuizUnit(dataActivitiesUnit?.data?.quiz  || 0)
            setMemoryGameUnit(dataActivitiesUnit?.data?.memoryGame  || 0)
            setIsLoading(false)
        })

        const responseUsersUnit = get(`${home}/dashboard/users/count?unit=${validation}`, myHeaders, refreshToken, history, setToken)
        responseUsersUnit.then(responseUsersUnit => { setUsersUnit(responseUsersUnit.data.count) })
    }

    useEffect(() => {

      
        getNewData()
      

    }, [unity])

 

    const returnTables = () => {
        if (tables) {
            return <>
                <DashboardRankGeral rankPagination={rankPagination} title="Ranking Geral" unityTitle="Unidade / Grupo" rank={rankGeral} isLoading={ isGeralLoading} />
                <DashboardRankGeral rankPagination={rankPagination} title="Ranking Empregados (P e T)" unityTitle="Unidades" rank={rankEmployee} isLoading={ isEmployeeLoading } />
                <DashboardRankGeral rankPagination={rankPagination} title="Ranking Família" unityTitle="Grupos" rank={rankFamily} isLoading={isFamilyLoading}/>
            </>
        } else { return <DashboardRankUnit title="Ranking" unityTitle="Grupos" rank={rankUnit} /> }
    }

    return (
        <div className="dashboard">
            <DashboardNav userName={userName}
            tables={tables} 
            subscribersUsersgeral={usersgeral} 
            subscribersUsersUnit={usersUnit} 
            lecturesGeral={lecturesgeral} 
            lecturesUnit={lecturesUnit} 
            phrasesGeral={phrasesGeral} 
            phrasesUnit={phrasesUnit} 
            quizGeral={quizGeral} 
            quizUnit={quizUnit} 
            memoryGameGeral={memoryGameGeral} 
            memoryGameUnit={memoryGameUnit} 
            totalUsers={totalUsers} 
            rankGeral={rankGeral} 
            rankEmployee={rankEmployee} 
            rankFamily={rankFamily} 
            rankUnit={rankUnit} 
            unity={unity}            
            
            />
            <div className="dashboard-content">
                <div className="dashboard-contentTop">
                    <div className="dashboard-contentTopleft">
                        <div className="dashboard-contentTopleftup">
                            <DashboardFilter tables={tables} setTables={setTables} setUnity={setUnity} setIsLoading={setIsLoading} isLoading={isLoading} />
                            <DashboardCardSubscribers text="Quantidade de inscritos" value={tables ? usersgeral : usersUnit} isLoading={isLoading} />
                        </div>
                        <div className="dashboard-contentTopleftdown">
                            <DashboardSmCard text="Palestras Concluídas" isLoading={isLoading} value={tables ? lecturesgeral : lecturesUnit} />
                            <DashboardSmCard text="SIPAT Frases Concluídos" isLoading={isLoading} value={tables ? phrasesGeral : phrasesUnit} />
                            <DashboardSmCard text="Quiz Concluídos" isLoading={isLoading} value={tables ? quizGeral : quizUnit} />
                            <DashboardSmCard text="Jogo da Memória Concluídas" isLoading={isLoading} value={tables ? memoryGameGeral : memoryGameUnit} />
                            <DashboardSmCard text="Caça-Palavras Concluídos" isLoading={isLoading} value={tables ? huntWords : phrasesUnit} />
                        </div>
                    </div>
                    <div className="dashboard-contentTopRight" style={{ display: tables ? 'initial' : 'none' }}>
                        <DashboardChart totalUsers={totalUsers} />
                    </div>
                </div>
                <div className="dashboard-contentBottom">
                    {returnTables()}
                </div>
            </div>

        </div>
    )
    }