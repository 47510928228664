import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Dashboard from '../Pages/Dashboard';
import Login from '../Pages/Login';



export default function Routes() {
     const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
    return (
        <Router>
            <Switch>
                {user && <Route path='/dashboard'>
                    <Dashboard user={user} />
                    </Route>}
                <Route exact path='/'><Login user={user} setUser={setUser} /></Route>
            </Switch>
        </Router>
    );
}